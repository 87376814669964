import * as React from "react";
import './Hero.scss';
import {useEffect, useState} from "react";
import {useWindowSize} from "../hooks/useWindowSize";

const Hero = ({ title, h1, width = 50 }) => {
    const [titleWidth, setTitleWidth] = useState(width);
    const windowSize = useWindowSize([]);

    // Use the requested width for the title BUT on smaller screens make it wider (80%)
    useEffect(() => {
        const refWidth = windowSize[0];
        let w = width;
        if (refWidth > 0 && refWidth < 850) {
            w = 80;
        }
        setTitleWidth(w);
    }, [windowSize, width]);

    return (
        <div className="hero">
            <div className="hero-content">
                {h1 &&
                    <h1>
                        <span style={{ width: `${titleWidth}%` }}>{h1}</span>
                    </h1>
                }

                {title &&
                    <div className="title">
                        <span style={{ width: `${titleWidth}%` }}>{title}</span>
                    </div>
                }
            </div>
        </div>
    );
}
export default Hero;
