import * as React from "react"
import Helmet from 'react-helmet'
import Navbar from "../components/Navbar";
import {StaticImage} from "gatsby-plugin-image";
import '../css/home.scss';
import '../css/form.scss';
import '../css/grid.scss';
import '../css/global.scss';
import '../css/prod-spec-col.scss';
import '../css/section.scss';
import '../css/utility-classes.scss';
import ButtonLink from "../components/ButtonLink";
import Hero from "../components/Hero";
import Footer from "../components/Footer";
import {Link} from "gatsby";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faTrafficLight, faFileMedicalAlt, faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons'


// markup
const IndexPage = () => {
    return (
        <>
            <Helmet>
                <title>Careweb Consultancy | Meneer Jansen ICT</title>
                <meta name="description" content="Careweb consultancy - Careweb op maat voor jullie organisatie: management rapportages, signalering, brieven en facturen in eigen huisstijl.)"/>
                <meta property="og:title" content="Careweb Consultancy | Meneer Jansen ICT"/>
                <meta property="og:url" content="https://meneerjansen-ict.nl/"/>
                <meta property="og:description" content="Careweb consultancy, maatwerk aanpassingen en training"/>
                <meta property="og:image" content="https://meneerjansen-ict.nl/images/logo.png"/>
                <body className="page-home" />
            </Helmet>

            <Navbar/>

            <main>
                <div className="hero-wrapper">
                    <Hero h1="Careweb op maat voor jullie organisatie"/>
                    <StaticImage src="../images/pexels-luis-gomes-546819.jpg" alt="homepage header" className="hero-image" />
                </div>

                <article className="bg-grey extensions">

                    <div className="content">

                        <div className="cols center-cols">

                            <div className="col-md-33">
                                <Link to="/careweb/afspraak-reminders" className="invisible-link">
                                    <StaticImage src="../images/pexels-mary-taylor-6009145.jpg" alt="Foto van een man die een sms ontvangt"/>

                                    <h3>Afspraak reminders</h3>
                                    <section className="text-based">
                                        <p>Verlaag het aantal no-shows met afspraak reminders!</p>
                                    </section>
                                </Link>
                                <Link to="/careweb/afspraak-reminders">Meer over afspraak reminders</Link>
                            </div>

                            <div className="col-md-33">
                                <Link to="/careweb/website-koppeling-aanmelding" className="invisible-link">
                                    <StaticImage src="../images/pexels-andrea-piacquadio-3807747.jpg" alt="Vrouw die computer gebruikt"/>

                                    <h3>Website koppeling</h3>
                                    <section>
                                        <p>Bespaar tijd door aanmeldingen vanaf je website direct in Careweb te schieten.</p>
                                    </section>
                                </Link>
                                <Link to="/careweb/website-koppeling-aanmelding">Meer over website koppeling</Link>

                            </div>

                            <div className="col-md-33">
                                <Link to="/careweb/dossier-compleet" className="invisible-link">
                                    <StaticImage src="../images/pexels-davis-sanchez-1727004.jpg" alt="Afbeelding van een verkeerslicht"/>


                                    <h3>Dossier compleet</h3>
                                    <section>
                                        <p>In één oogopslag zien of het dossier in Careweb volledig is.</p>
                                    </section>
                                </Link>
                                <Link to="/careweb/dossier-compleet">Meer over dossier compleet</Link>
                            </div>
                        </div>
                        {/*<div className="cols center-cols">*/}
                        {/*    <div className="col-md-33">*/}
                        {/*        <StaticImage src="../images/computer-hand.jpg" alt="Vrouw die computer gebruikt"/>*/}

                        {/*        <h3>Extra product</h3>*/}
                        {/*        <section>*/}
                        {/*            <p>Laat (toekomstige) cliënten zelf een afspraak inplannen op jullie website.</p>*/}
                        {/*        </section>*/}
                        {/*        <Link to="/contact">Neem contact op</Link>*/}
                        {/*    </div>*/}

                        {/*    <div className="col-md-33">*/}
                        {/*        <StaticImage src="../images/computer-hand.jpg" alt="Vrouw die computer gebruikt"/>*/}

                        {/*        <h3>Extra product</h3>*/}
                        {/*        <section>*/}
                        {/*            <p>Laat (toekomstige) cliënten zelf een afspraak inplannen op jullie website.</p>*/}
                        {/*        </section>*/}
                        {/*        <Link to="/contact">Neem contact op</Link>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        <div className="cols">
                            <div className="align-center col-xs-100">
                                <section className="ButtonContainer">
                                    <ButtonLink label="Bekijk alle uitbreidingen" to="/careweb"/>
                                </section>
                            </div>
                        </div>
                    </div>
                </article>

                {/*<article className="about-me">*/}
                {/*    <div className="content">*/}
                {/*        <div className="cols">*/}
                {/*            <div className="col-xs-16">*/}
                {/*            </div>*/}
                {/*            <div className="col-xs-50 col-sm-25">*/}
                {/*                <StaticImage src="../images/MeneerJansen-foto.jpg" alt="Foto van Meneer Jansen"/>*/}
                {/*            </div>*/}

                {/*            <div className="col-sm-50 align-vertical-center">*/}
                {/*                <section className="text-based">*/}
                {/*                    <h3>*/}
                {/*                        Hallo, ik ben Jeroen Jansen.*/}
                {/*                    </h3>*/}
                {/*                    <p>*/}
                {/*                        Als technisch consultant heb ik mij gespecialiseerd in <a href="https://careweb.nl/" target="_blank" rel="noreferrer">Careweb</a>, het webbased EPD voor de GGZ en sociaal domein.*/}
                {/*                    </p>*/}
                {/*                    <Link to="/over-meneer-jansen">Lees meer over mij</Link>*/}
                {/*                </section>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</article>*/}

                {/*<article className="bg-grey">*/}
                {/*    <Testimonials />*/}
                {/*</article>*/}

                <article>

                    <div className="content">

                        <div className="cols">
                            <div className="col-xs-100 align-center">
                                <h2>Ook beschikbaar voor maatwerk</h2>
                            </div>
                        </div>


                        <ul className="cols check home-icons">

                            <li className="col-md-25 top-check">
                                <FontAwesomeIcon icon={faCopy} size="3x" className="home-icon" />
                                Eigen logo en huisstijl op brieven of facturen
                            </li>

                            <li className="col-md-25 top-check home-icons bubble">
                                <FontAwesomeIcon icon={faTrafficLight} size="3x" className="home-icon" />
                                Meldingen in het dossier of op het hoofdmenu
                            </li>

                            <li className="col-md-25 top-check home-icons crossmark">
                                <FontAwesomeIcon icon={faFileMedicalAlt} size="3x" className="home-icon" />
                                Rapportages
                            </li>

                            <li className="col-md-25 top-check home-icons cloud-check">
                                <FontAwesomeIcon icon={faEnvelopeOpenText} size="3x" className="home-icon" />
                                Brieven
                            </li>

                        </ul>

                        <div className="align-center col-xs-100">
                            <section className="ButtonContainer">
                                <ButtonLink label="Neem contact op" to="/contact"/>
                            </section>
                        </div>
                    </div>

                </article>
            </main>

            <Footer />
        </>

    )
}

export default IndexPage
